import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { ErrorBoundaryWithLocation } from '@common/react/components/UI/ErrorBoundary/ErrorBoundary';
import { loadable, loadableDelay } from '@common/react/loadable/loadableSettings';
import BaseSecureRouteWrapper from '@common/react/components/Routes/SecureRoute/BaseSecureRouteWrapper';

import DbaLoader from '@app/components/UI/DbaLoader/DbaLoader';

const params = {
	fallback: <DbaLoader />,
};

const DashboardLayout = loadable(() => loadableDelay(/* webpackChunkName: "DashboardLayout" */
	import('@app/components/UI/DashboardLayout/DashboardLayout'),
), params);

interface Props {
	path: string;
	component: any;
	redirectPath?: string;
	title?: string;
}

const DashboardRoute: React.FC<Props> = ({
	component: Component, redirectPath = '/', title, ...rest
}) => {
	return <BaseSecureRouteWrapper path={rest.path as string} redirectPath={redirectPath}>
		<DashboardLayout>
			{title && <Helmet>
				<title>{title}</title>
			</Helmet>}
			<ErrorBoundaryWithLocation>
				{Component ? <Component /> : <Outlet />}
			</ErrorBoundaryWithLocation>
		</DashboardLayout>
	</BaseSecureRouteWrapper>;
};

export default DashboardRoute;
